<template>
<div id="indexlayout">
  <layout-index-left />
  <div id="indexlayout-right" :class="{'fiexd-header': siteFiexdHeader}">
    <layout-index-right-top />
    <div class="indexlayout-right-main">
      <div class="main-conent main-conent-minheight">
        <el-card shadow="never" class="border-none margin-t24">
          <el-form :model="form" label-width="120px" :rules="rules" ref="form">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="form.name" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="identity" label-width="120px">
              <el-input v-model="form.identity" type="text" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="手机" prop="phone" label-width="120px">
              <el-input v-model="form.phone" type="text" style="width:400px"></el-input>
            </el-form-item>

            <el-form-item label="地址" prop="address" label-width="120px">
              <el-input v-model="form.address" type="text" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="基础疾病" prop="disease" label-width="120px">
              <el-checkbox-group v-model="form.disease">
                <el-checkbox label="高血压"></el-checkbox>
                <el-checkbox label="糖尿病"></el-checkbox>
                <el-checkbox label="重型神经病"></el-checkbox>
                <el-checkbox label="脑卒中"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>

          </el-form>
          <!--<el-button type="primary" style="margin-left:120px;margin-top:10px;" @click="readCard">读取身份证</el-button>-->
          <el-button type="primary" style="margin-left:120px;margin-top:10px;" @click="submitForm('form')">完成</el-button>
        </el-card>
      </div>
      <layout-index-right-footer />
    </div>
  </div>

</div>
</template>

<script>
import {
  mapGetters
} from 'vuex';
import LayoutIndexLeft from '@/layout/Index/LayoutIndexLeft';
import LayoutIndexRightTop from '@/layout/Index/LayoutIndexRightTop';
import LayoutIndexRightFooter from '@/layout/Index/LayoutIndexRightFooter';
import request from '@/service/lib/request';
export default {
  components: {
    LayoutIndexLeft,
    LayoutIndexRightTop,
    LayoutIndexRightFooter
  },
  data() {

    return {
      radio1: '全部',
      input1: '',
      createDialogFlag: false,
      form: {
        name: '',
        password: '',
        new_password: '',
        new_password2: '',
        disease: []
      },

      rules: {

      }
    };
  },
  computed: {
    ...mapGetters([
      'siteFiexdHeader'
    ])
  },
  created() {
    this.init();
  },
  methods: {
            readCard() {
          
           
    },
    init() {
      this.form.name = this.$store.getters.name;
    },
    handleClick(row) {
      console.log(row);
    },
    submitForm() {
      console.log(this.form, "from");
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.createUser();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    createUser() {
      request.post('/man/create/', this.form)
        .then((res) => {
          console.log(res);
          if (res.code === "success") {
            this.$message({
              type: "success",
              message: "修改密码成功！请重新登录！"
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

#indexlayout {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

#indexlayout-right {
  position: relative;
  flex: 1;
  overflow: auto;
  background-color: $mainBgColor;

  &.fiexd-header {
    display: flex;
    flex-direction: column;

    .indexlayout-right-main {
      flex: 1;
      overflow: auto;
    }
  }

}
</style>
